<template>
  <div class="backNavHeader">
    <div class="logo"><img src="../../../assets/images/bglogo.png">山水物联</div>
    <router-link class="nav-item" to="/backstage/home"><i class="icon el-icon-location" />首页</router-link>
    <router-link class="nav-item" to="/backstage/news"><i class="icon el-icon-menu" />行业新闻</router-link>
    <router-link class="nav-item" to="/backstage/join"><i class="icon el-icon-setting" />加入我们</router-link>
    <router-link class="nav-item" to="/backstage/enterprise"><i class="icon el-icon-office-building" />企业信息</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
.backNavHeader {
  position:fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background: #1e376f;

  .logo{
    text-align: center;
    padding:10px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 3px;
    img{
      height: 50px;
      vertical-align:middle;
      margin-right: 8px;
    }
  }
  .el-menu-item i {
    color: #fff;
  }
  .el-menu{border:none;}
  .nav-item{
    display: block;
    font-size: 14px;
    line-height: 50px;
    margin-top:8px;
    color: #fff;
  }
  .icon{padding: 0 12px 0 40px; font-size: 18px; vertical-align: middle;}
  .active{
    background: #0062db;
  }
}

</style>
