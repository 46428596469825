<template>
  <div>
    <BackNavHeader />
    <div class="router-view">
      <router-view />
    </div>
  </div>
</template>

<script>
import BackNavHeader from './../components/vbackstage/common/backNavHeader.vue'

export default {
  name: '',
  components: {
    BackNavHeader
  }
}
</script>

<style lang="scss">
// body {font-family:  "Microsoft Yahei", Arial, "Helvetica Neue", Helvetica, sans-serif;color: #414141;line-height: 1;-webkit-text-size-adjust: none;  min-width:1200px; width:auto!important; width:1200px;_width:expression(document.documentElement.clientWidth<1200?"1200px":"auto"); margin:0 auto;}

.router-view{
  padding:0 0 0 200px;
}

</style>
